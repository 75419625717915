import { createWebHashHistory, createWebHistory, createRouter } from "vue-router";
import routerTest from "@/routes/test.js";

const routes = [
	{
    name: "splash",
		path: "/",
		component: () => import("@/views/main/Splash.vue"),
		meta: { layout: "WideLayout" }
  },
	{
    name: "photos",
		path: "/photos",
		component: () => import("@/views/album/Photos.vue"),
  },
  {
    path: '/main',
		component: () => import("@/views/main/Main.vue"),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import("@/views/main/Home.vue"),
      },
      {
        path: 'album',
        name: 'album',
        component: () => import("@/views/album/Album.vue"),
      },
      {
        path: 'education',
        name: 'education',
        component: () => import("@/views/education/Lectures.vue"),
      },
    ]
  },
	{
    name: "photo",
		path: "/photo",
    component: () => import("@/views/album/AnalyzePhoto.vue"),
		//component: () => import("@/views/main/Photo.vue"),
  },
	{
    name: "lecture",
		path: "/lecture",
    component: () => import("@/views/education/Lecture.vue"),
  },
  ...routerTest,
];

const router = createRouter({
  history: process.env.VUE_APP_IS_ELECTRON === "true" ? createWebHashHistory() : createWebHistory(),
  routes,
});

export { router };
