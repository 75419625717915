import { createApp } from "vue";
import App from "./App.vue";
import { router } from "@/routes/index.js";
import { i18n } from "@/i18n/index.js";
import store from "@/store/index.js";

/**
 * Bootstrap 설정
 */
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
/**
 * VueSidebarMenu 설정
 */
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();

import { GesturePlugin } from '@vueuse/gesture'
import Vue3Toastify from 'vue3-toastify';

// ---------------------------------
// app 선언
// ---------------------------------
const app = createApp(App);
app.use(router);              // 라우터 사용
app.use(store);               // vuex 사용
app.use(i18n);                // 다국어 사용
app.use(BootstrapVue3)
app.use(VueSidebarMenu)
app.use(GesturePlugin)
app.use(Vue3Toastify);
app.component('font-awesome-icon', FontAwesomeIcon)
app.config.devtools = true
app.mount("#app");
